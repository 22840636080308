@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: #bfdde0;

    --muted: #bfdde0;
    --muted-foreground: #bfdde0;

    --popover: 0 0% 100%;
    --popover-foreground: #bfdde0;

    --border: #10a0b0;
    --input: #10a0b0;

    --card: 0 0% 100%;
    --card-foreground: #bfdde0;

    --primary: #10a0b0;
    --primary-foreground: #10a0b0;

    --secondary: #bfdde0;
    --secondary-foreground: #bfdde0;

    --accent: #bfdde0;
    --accent-foreground: #bfdde0;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
  .standard-border {
    @apply border-2 border-gray-200 rounded-3xl;
  }
}

.date-picker-rounded {
  display: block;
  border-radius: 50px;
  padding: 6px;
  width: 100%;
  border: 0.1px solid #d1d5db !important;
  margin-top: 4px;
}

.date-picker {
  display: block;
  border-radius: 5px;
  padding: 6px;
  width: 100%;
  border: 0.1px solid #d1d5db !important;
  margin-top: 4px;
}

.calendar {
  display: block;
  border-radius: 50px;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  background-color: #6c757d;
  color: white;
  font-size: 15px;
}

.calendar::placeholder,
.calendar-rounded::placeholder {
  text-align: left;
  color: #fff;
  opacity: 0.8;
}

.date-picker::placeholder,
.date-picker-rounded::placeholder {
  text-align: center;
  color: #6c757d;
  opacity: 1;
}
